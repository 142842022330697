<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="projectModel"
    label-width="130px"
    >
    <el-form-item label="名称：" prop="projectName">
      <el-input
        type="text"
        v-model="projectModel.projectName">
      </el-input>
    </el-form-item>
    <el-form-item label="别名：" prop="projectAlias">
      <el-input
        type="text"
        v-model="projectModel.projectAlias">
      </el-input>
    </el-form-item>
    <el-form-item label="业主代号：" prop="ownerCode">
      <el-input
        type="text"
        v-model="projectModel.ownerCode">
      </el-input>
    </el-form-item>
    <el-form-item label="所属公司：" prop="companyGuid">
      <el-select v-model="projectModel.companyGuid" clearable>
        <el-option
          v-for="company in companys"
          :key="company.companyGuid"
          :label="company.companyName"
          :value="company.companyGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="清单是否展示层级">
      <el-switch
        v-model.number="projectModel.listLevelDisplay"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        >
      </el-switch>
    </el-form-item>
    <el-form-item label="年数" prop="yearCount">
      <el-input-number
        v-model="projectModel.yearCount"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="projectModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import ProjectModel from '@/model/ProjectModel'
import companyService from '@/services/companyService'

export default {
  name: 'ProjectForm',
  props: {
    projectModel: {
      type: ProjectModel,
      default () {
        return new ProjectModel()
      }
    }
  },
  data () {
    return {
      companys: [],
      rules: {
        projectName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        projectAlias: [
          {
            required: true,
            message: '请输入别名',
            trigger: 'blur'
          }
        ],
        ownerCode: [
          {
            required: true,
            message: '请输入业主代号',
            trigger: 'blur'
          }
        ],
        companyGuid: [
          {
            required: true,
            message: '请选择所属公司',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getCompanys () {
      companyService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.companys = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getCompanys()
  }
}
</script>
