import utility from '@/common/utility'
import auth from '@/common/auth'

export default class ProjectModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.projectGuid
      this.projectGuid = props.projectGuid
      this.projectName = props.projectName
      this.projectAlias = props.projectAlias
      this.ownerCode = props.ownerCode
      this.companyGuid = props.companyGuid
      this.companyName = props.companyName
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.listLevelDisplay = props.listLevelDisplay
      this.yearCount = props.yearCount
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.projectName) this.projectName = ''
    if (!this.projectAlias) this.projectAlias = ''
    if (!this.ownerCode) this.ownerCode = ''
    if (!this.companyGuid) this.companyGuid = ''
    if (!this.companyName) this.companyName = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.listLevelDisplay) this.listLevelDisplay = 0
    if (!this.yearCount) this.yearCount = 0
  }

  generatePrimaryKey () {
    this.projectGuid = utility.getUuid()
    this.resourceId = this.projectGuid
  }
}
